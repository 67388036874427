/* App.css */

/* Import Bulma CSS if not already imported elsewhere */
@import 'https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css';

/* 1) Remove default browser margins and padding, set background to white */
html,
body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  /* White page background */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Enhance the Tabs Container */
.tabs {
  margin-bottom: 20px;
  justify-content: center;
  /* Center the tabs horizontally */
}

/* Style Each Tab Link (default) */
.tabs ul li a {
  background-color: #f5f5f5;
  /* Light gray background for tabs */
  color: #363636;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 5px;
  transition: background-color 0.3s, color 0.3s;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: none;
  /* Remove default Bulma tab border */
}

/* Hover Effect for Tab Links */
.tabs ul li a:hover {
  background-color: #e0e0e0;
  color: #000000;
}

/* Active Tab Styling (Default) */
.tabs ul li.is-active a {
  background-color: #00d1b2;
  /* Teal background for active tab */
  color: #ffffff;
  font-weight: bold;
}

/* If using is-boxed, keep the teal background on active tab as well. */
.tabs.is-boxed li.is-active a {
  background-color: #00d1b2;
  color: #fff;
}

/* Toggle + Rounded + Large Tabs (if using is-toggle is-toggle-rounded is-large) */
.tabs.is-toggle.is-toggle-rounded.is-large ul li a {
  border-radius: 30px;
  padding: 10px 25px;
  font-size: 1rem;
}

.tabs.is-toggle.is-toggle-rounded.is-large li.is-active a {
  background-color: #00d1b2;
  color: #fff;
  font-weight: bold;
  border-radius: 30px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .tabs ul li a {
    padding: 8px 12px;
    font-size: 14px;
  }
}

/* Sticky Summary Box */
.sticky-summary {
  position: sticky;
  top: 1rem;
  /* or desired offset */
}

/* Modal Customization (if needed) */
.modal-content .box {
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
}

/* Adjust buttons spacing */
.buttons>.button {
  margin-right: 10px;
  margin-bottom: 10px;
}

/* Table Styles */
.table th,
.table td {
  vertical-align: middle;
}

/* Optional: Adjust Input Field Styles */
.input {
  height: 40px;
  font-size: 16px;
}

/* Optional: Adjust button styles for better visibility */
.button.is-primary {
  background-color: #00d1b2;
  border-color: transparent;
}

.button.is-danger {
  background-color: #ff3860;
  border-color: transparent;
}

.button.is-info {
  background-color: #209cee;
  border-color: transparent;
}

.button.is-warning {
  background-color: #ffdd57;
  border-color: transparent;
}

/* Ensure the modal is above other elements */
.modal {
  z-index: 50;
}

/* Additional Custom Styles */

/* Form Section Background */
.section.has-background-light {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Summary Box Styling */
.box.sticky-summary {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Adjust title sizes for better hierarchy */
.title.is-4 {
  margin-bottom: 15px;
}

.title.is-5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* ---------- NEW: Card Color Updates ---------- */
/* Give cards a subtle off-white background and a gentle border */
.card {
  background-color: #f9fafb;
  /* Slightly off-white to contrast with the white page */
  border: 1px solid #ddd;
  /* Light border for definition */
  border-radius: 8px;
  /* Slight rounding of corners */
  box-shadow: none;
  /* Remove Bulma's default shadow if you want a simpler look */
}

/* Make the card header a light gray to separate it from the body */
.card-header {
  background-color: #eef1f4;
  /* Light gray header area */
  border-bottom: 1px solid #ddd;
}

/* Ensure the card header text is readable */
.card-header-title {
  color: #363636;
  font-weight: 600;
}